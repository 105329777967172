<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <EmployeeCard></EmployeeCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <ProfileProgress></ProfileProgress>
        <div class="mt-4 settingsBody rounded">
          <div class="pt-3 pb-0 ps-4">
            <strong>Afiliación</strong>
          </div>
          <hr />
          <div>
            <form class="settingForm" @submit.prevent="sendBasic">
              <div class="mb-3">
                <label for="EPS" class="form-label">Nombre de la EPS</label>
                <select
                  class="form-select text-capitalize"
                  name="EPS"
                  v-model="EPS"
                  required
                >
                  <option
                    v-for="EPS in EPSList"
                    v-bind:value="EPS._id"
                    :key="EPS._id"
                    >{{ EPS.name }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="AFP" class="form-label"
                  >Nombre del Fondo de Pensión</label
                >
                <select
                  class="form-select text-capitalize"
                  name="AFP"
                  v-model="AFP"
                  required
                >
                  <option
                    v-for="AFP in AFPList"
                    v-bind:value="AFP._id"
                    :key="AFP._id"
                    >{{ AFP.name }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="CCF" class="form-label"
                  >Nombre de la Caja de Compensación</label
                >
                <select
                  class="form-select text-capitalize"
                  name="CCF"
                  v-model="CCF"
                  required
                >
                  <option
                    v-for="CCF in CCFList"
                    v-bind:value="CCF._id"
                    :key="CCF._id"
                    >{{ CCF.name }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="ARLRate" class="form-label"
                  >Tasa de Riesgo Según ARL</label
                >
                <select
                  class="form-select text-capitalize"
                  name="ARLRate"
                  v-model="ARLRate"
                  @change="checkARLPlan"
                  required
                >
                  <option
                    v-for="ARLRate in ARLRateList"
                    v-bind:value="ARLRate"
                    :key="ARLRate"
                    >Riesgo {{ ARLRate }}</option
                  >
                </select>
              </div>
              <button type="submit mb-3" class="btn btn-primary shadow-primary">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.settingForm {
  margin: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 280px;
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>

<script>
import Legal from "@/components/layout/Legal";
import EmployeeCard from "@/views/employee/EmployeeCard";
import ProfileProgress from "@/views/employee/ProfileProgress";

import Storage from "@/modules/storage";
import Swal from "sweetalert2";

export default {
  name: "Employee Profile Edit Membership",
  components: { Legal, EmployeeCard, ProfileProgress },
  data() {
    return {
      EPS: "",
      EPSList: [],
      AFP: "",
      AFPList: [],
      CCF: "",
      CCFList: [],
      ARLRate: "",
      ARLRateList: []
    };
  },
  beforeCreate() {
    const store = new Storage();

    store.getData("employee", this.$route.params._id).then(employeeData => {
      store.getData("branch", employeeData.branch).then(branchData => {
        store.getData("city", branchData.city).then(cityData => {
          localStorage.setItem(
            "currentLocation",
            "Empleados / Perfiles / " +
              cityData.name +
              " / " +
              employeeData.name +
              " / Afiliación"
          );
        });
      });
    });
  },
  mounted() {
    const store = new Storage();

    store.getData("EPS").then(EPS => {
      EPS.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      this.EPSList = EPS;
    });
    store.getData("CCF").then(CCF => {
      CCF.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      this.CCFList = CCF;
      this.CCFList.push({ _id: null, name: "No Aplica" });
    });
    store.getData("AFP").then(AFP => {
      AFP.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      this.AFPList = AFP;
      this.AFPList.push({ _id: null, name: "No Aplica" });
    });
    store.getData("schema").then(schemaList => {
      schemaList.forEach(schema => {
        if (schema.store === "employee") {
          this.ARLRateList = schema.data.ARLRate.enum;
        }
      });
    });
    store.getData("employee", this.$route.params._id).then(employee => {
      this.EPS = employee.EPS;
      this.AFP = employee.AFP;
      this.CCF = employee.CCF;
      this.ARLRate = employee.ARLRate;
    });
  },
  methods: {
    async checkARLPlan() {
      const store = new Storage();
      const planData = await store.getData(
        "plan",
        localStorage.getItem("subscription.plan")
      );
      const risk = planData.restrictions.risk5;

      if (!risk && this.ARLRate == "V") {
        this.ARLRate = "";
        Swal.fire({
          title: "No es posible asignar riesgo 5",
          icon: "warning",
          text:
            "La susbscripción que posee no es compatible con empleados de riesgo 5. Para hacerlo por favor cambie de plan.",
          showCancelButton: true,
          confirmButtonText: `Cambiar Plan`,
          cancelButtonText: "Continuar",
          cancelButtonColor: "#6c757d"
        }).then(result => {
          if (result.isConfirmed) {
            this.$router.push({ name: "Subscription" });
          }
        });
      }
    },
    sendBasic() {
      Swal.fire({
        icon: "warning",
        title: "Actualizando información",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      const store = new Storage();
      const data = {
        _id: this.$route.params._id,
        EPS: this.EPS,
        AFP: this.AFP,
        CCF: this.CCF,
        ARLRate: this.ARLRate
      };
      store
        .updateData("employee", [data], true, "optima")
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Datos Actualizados",
            text: "Todos los datos han sido sincronizados"
          }).then(() => {
            this.$router.go();
          });
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Error al actualizar los datos",
            text:
              "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
          }).then(result => {
            this.$router.go();
          });
        });
    }
  }
};
</script>
